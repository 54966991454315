import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Box, Button, Heading, Columns, Form } from "react-bulma-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
import generateUserAlias from '../../js/utils/UserAliasGenerator';
import styles from '../../styles/invite.module.css';

type Props = {
    invitedFrom: string | undefined,
    channelId: string | undefined
}

export default function InviteBox({ invitedFrom, channelId }: Props) {
    const [userAlias, setUserAlias] = useState("");
    const [fullscreen, showFullScreen] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setUserAlias(localStorage.getItem('userAlias') || generateUserAlias());
    }, []);

    function onJoinChannelClick() {
        localStorage.setItem('userAlias', userAlias);

        navigate(`/channel/${channelId}`);
    }

    return (
        <>
            <Modal show={true} showClose={false}>
                <Modal.Content className={styles.modal}>
                    <Box>
                        <Heading textAlign="center">{invitedFrom || "Someone"} has invited you to join this channel!</Heading>
                        <Columns>
                            <Columns.Column>
                                <Heading subtitle textAlign="center">Join from this browser</Heading>
                                <Form.Field>
                                    <Form.Label>Your name</Form.Label>
                                </Form.Field>
                                <Form.Field>
                                    <Form.Control>
                                        <Form.Input type="text" placeholder="Type your name here" onChange={({ target }) => setUserAlias(target.value)} value={userAlias}></Form.Input>
                                    </Form.Control>
                                </Form.Field>
                                <Button type='submit' rounded fullwidth color="primary" onClick={onJoinChannelClick}>Join</Button>
                            </Columns.Column>
                            <Columns.Column style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Heading subtitle textAlign="center">Scan the QR code below from an HoloLens</Heading>
                                <QRCode value={`holoxr://?id=${channelId}`}></QRCode>
                                <Button rounded fullwidth color="link" style={{ display: "flex", gap: 10, marginTop: 20 }} onClick={() => showFullScreen(true)}>
                                    <FontAwesomeIcon icon={faExpand} />
                                    Show in fullscreen
                                </Button>
                            </Columns.Column>
                        </Columns>              
                    </Box>
                </Modal.Content>
            </Modal>
            <Modal show={fullscreen} showClose={true} onClose={() => showFullScreen(false)} closeOnBlur={true} closeOnEsc={true}>
                <Modal.Content className={styles.fullscreen}>
                    <QRCode id="svg" value={`holoxr://?id=${channelId}`} size={Math.min(window.innerWidth, window.innerHeight) - 32} />
                </Modal.Content>
            </Modal>
        </>
    );
}