export default class ChannelConnectionEvents {
    private listeners: Map<string, ((args: any) => void)[]> = new Map();

    public addListener(event: string, action: (args:any) => void): void {
        if (!this.listeners.has(event)) {
            this.listeners.set(event, [action]);
        } else {
            const all = this.listeners.get(event) as ((args: any) => void)[];
            all.push(action);
            this.listeners.set(event, all);
        }
    }

    public removeListener(event: string, action: (args:any) => void): void {
        if (this.listeners.has(event)) {
            let all = this.listeners.get(event) as ((args: any) => void)[];
            all = all.filter(x => x !== action);
            this.listeners.set(event, all);
        }
    }

    public notify(event: string, args: any) {
        if (this.listeners.has(event)) {
            const all = this.listeners.get(event) as ((args: any) => void)[];
            all.forEach(a => a(args));
        }
    }
}