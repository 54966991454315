import { Button } from 'react-bulma-components';
import { faCamera, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayData } from './MCULayoutOverlay';
import styles from '../../styles/clientoverlay.module.css';

type Props = {
    overlay: OverlayData;
    onScreenshotClick: (userId: string) => void;
    onUploadClick: (userId: string) => void;
};

export default function ClientOverlay({ overlay, onScreenshotClick, onUploadClick }: Props) {
    function isHoloLens() {
        return overlay.roles && overlay.roles.includes("hololens");
    }

    return (
        <div style={{ top: overlay.top, left: overlay.left }} className={styles.client}>
            <p className={styles.name}>{overlay.userAlias}</p>
            {isHoloLens() &&
                <>
                    <Button className={styles.button} rounded color="primary" onClick={() => onScreenshotClick(overlay.userId)}>
                        <FontAwesomeIcon icon={faCamera} />
                    </Button>
                    <Button className={styles.button} rounded color="primary" onClick={() => onUploadClick(overlay.userId)}>
                        <FontAwesomeIcon icon={faFileImage} />
                    </Button>
                </>
            }
        </div>
    );
}