import { useState } from "react";

export default function useJoinedChannels() {
    var [channels, setChannels] = useState<Map<string, number>>(() => {
        const saved = localStorage.getItem("channels");
        return saved ? new Map(JSON.parse(saved)) : new Map();
    });

    function appendChannel(channelId: string): void {
        channels.set(channelId, Date.now());
        setChannels(channels);
        localStorage.setItem("channels", JSON.stringify(Array.from(channels.entries())));
    }

    return [channels, appendChannel] as const;
}