import { useEffect, useState } from "react";
import { Button } from "react-bulma-components";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChannelConnection from "../../js/connection/ChannelConnection";
import { toast } from "bulma-toast";

type Props = {
    connection: ChannelConnection | undefined,
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
};

export default function ScreenshareToggle({ connection, onClick }: Props) {
    const [presenter, setPresenter] = useState<string | undefined>();

    useEffect(() => {
        function onScreenshareUpdate({ userId, on }: {userId: string, on: boolean}) : void {
            setPresenter(on ? userId : undefined);
    
            if (on && !connection!.isMe(userId)) {
                toast({ message: `${connection?.getUserAliasById(userId) ?? "Someone"} has started a screen share.`, type: 'is-warning', position: 'top-center' });
            }
        }

        connection?.events.addListener("onscreenshareupdate", onScreenshareUpdate);

        return () => {
            connection?.events.removeListener("onscreenshareupdate", onScreenshareUpdate);
        }
    }, [connection]);

    function canScreenshare() : boolean {
        return presenter === undefined || connection!.isMe(presenter);
    }

    function getScreenshareTooltip() : string {
        let tooltip: string = "Toggle screen sharing";
        if (!canScreenshare()) {
            tooltip = `${connection?.getUserAliasById(presenter) ?? "Someone"} is currently sharing their screen`;
        }
        return tooltip;
    }

    function getScreenshareColor() : string {
        return presenter !== undefined ? "warning" : "success";
    }
    
    return (
        <Button disabled={!canScreenshare()}
                color={getScreenshareColor()}
                rounded
                data-tooltip={getScreenshareTooltip()}
                onClick={onClick}>
            <FontAwesomeIcon icon={faDesktop} />
        </Button>
    );
}