import { v4 } from "uuid";
import Config from "../../liveswitch_config.json";

function getAmountConnected(channelId: string, callback: (n: number) => void) {
	var request = new Request(
		`https://api.liveswitch.io/dashboard/query?api-version=1.0&tag=active_clients&applicationId=${Config.applicationId}&channelId=${channelId}`,
		{ method: 'GET', headers: new Headers({ "x-api-key": Config.apiKey })
	});
	
	fetch(request).then(resp => {
		return resp.json();
	}).catch(err => {
        console.log(err);
		callback(0);
	}).then(data => {
		callback(data.length);
	});
}

function getNewChannel(callback: (g: string) => void) {
    let length = 5;

    let guid = v4().substring(0, length);
    callback(guid);

    // fixme: API request issue
    // getAmountConnected(guid, count => {
    //     if (count > 0) {
    //         console.log(`Channel ${guid} is already opened, trying for another.`);
    //     }
    //     else {
    //         callback(guid);
    //     }
    // });
}

const httpRequest = {
    getAmountConnected,
    getNewChannel
};

export default httpRequest;