import styles from '../styles/channel.module.css';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faVideo, faMicrophone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'bulma-toast';
import Div100vh from 'react-div-100vh'
import LeaveChannelModal from '../components/channel/LeaveChannelModal';
import ChatBox from '../components/channel/ChatBox';
import ChannelConnection from '../js/connection/ChannelConnection';
import generateUserAlias from '../js/utils/UserAliasGenerator';
import ScreenshareToggle from '../components/channel/ScreenshareToggle';
import MCULayoutOverlay from '../components/channel/MCULayoutOverlay';
import PainterroUtils from '../components/channel/PainterroUtils';
import useJoinedChannels from '../js/utils/useJoinedChannels';

export default function Channel() {
    // State to force a render of components
    const [, forceRender] = useState({});
    const [connection] = useState(new ChannelConnection());

    const navigation = useRef(useNavigate());
    const channelId = useRef(useParams().id);
    const joinedChannels = useRef(useJoinedChannels()[1]);

    useEffect(() => {
        function onError(error: string): void {
            toast({ message: error, type: 'is-danger', position: 'top-center', dismissible: true, duration: 20000, pauseOnHover: true });
        }

        function onScreenshot({ userId, videoFrameData }: { userId: string, videoFrameData: VideoFrameData }): void {
            const painterroUtils = new PainterroUtils();

            painterroUtils.show(videoFrameData.Data, (base64Image, annotations) => {
                const newFrameData: VideoFrameData = videoFrameData;
                // Remove the header since we only want to send the data
                newFrameData.Data = base64Image.split(",", 2)[1];

                const imageData: ImageAnnotationData = {
                    VideoFrameData: newFrameData,
                    Annotations: annotations
                };

                connection.messager.sendImage(userId, imageData).then(_ => {
                    toast({ message: "Image sent!", type: 'is-success', position: 'top-center' });
                }).fail(ex => onError(ex.message));
            });
        }

        if (channelId.current) {
            connection.connectToChannel(channelId.current);
            joinedChannels.current(channelId.current);
            connection.events.addListener("onerror", onError);
            connection.events.addListener("onscreenshot", onScreenshot);
        }

        return () => {
            connection.events.removeListener("onerror", onError);
            connection.events.removeListener("onscreenshot", onScreenshot);
            connection.leaveChannel();
        }
    }, [connection]);

    return (
        <Div100vh className={styles.video} id="video">
            <div id={styles.footer} className="buttons is-centered">
                <Button color="link" rounded data-tooltip="Invite people" onClick={onInviteClick}>
                    <FontAwesomeIcon icon={faUserPlus} />
                </Button>
                <Button color={connection.isLocalVideoEnabled ? "primary" : ""} rounded data-tooltip="Toggle local audio" onClick={onToggleVideoClick}>
                    <FontAwesomeIcon icon={faVideo} />
                </Button>
                <Button color={connection.isLocalAudioEnabled ? "primary" : ""} rounded data-tooltip="Toggle local audio" onClick={onToggleAudioClick}>
                    <FontAwesomeIcon icon={faMicrophone} />
                </Button>
                <ScreenshareToggle connection={connection} onClick={onToggleScreenshareClick} />
                <Button color="danger" rounded data-tooltip="Leave this channel" onClick={onLeaveCallClick}>
                    <FontAwesomeIcon icon={faPhoneSlash} />
                </Button>
            </div>

            <MCULayoutOverlay connection={connection} />

            <ChatBox connection={connection} />

            <LeaveChannelModal title="Leave the channel?" onSuccess={() => navigation.current("/")} onCancel={onLeaveCallClick} />
        </Div100vh>
    )

    function onInviteClick() {
        let userAlias = localStorage.getItem('userAlias') ?? generateUserAlias();
        let url = `${window.location.origin}/invite/${channelId.current}/${userAlias}`;
        navigator.clipboard.writeText(url);

        toast({ message: "Invite link copied to clipboard!", type: 'is-success', position: 'top-center' });
    }

    function onToggleVideoClick() {
        connection.toggleLocalVideo();
        forceRender({});
    }

    function onToggleAudioClick() {
        connection.toggleLocalAudio();
        forceRender({});
    }

    function onToggleScreenshareClick() {
        connection.screenshare.toggleScreenshare();
        forceRender({});
    }

    function onLeaveCallClick() {
        const confirmLeave = document.getElementById("confirmLeave");
        if (confirmLeave) {
            confirmLeave.classList.toggle('is-active');
        }
    }
}
